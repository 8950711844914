import { Pagination, Table } from "antd";
function DataTable(props: any) {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
      <Pagination
        current={props?.page || 1}
        pageSize={props?.pageSize || 10}
        total={props?.meta?.itemCount || 0}
        defaultCurrent={1}
        responsive={true}
        defaultPageSize={props?.pageSize || 10}
        disabled={false}
        hideOnSinglePage={true}
        onChange={props?.handlePageChange}
        style={{ marginTop: "20px", textAlign: "center" }}
      />
    </>
  );
}

export default DataTable;
