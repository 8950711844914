import React, { useEffect, useState } from "react";
import PageHeader from "../../components/pageHeader";
import LoadingBox from "../../components/loading";
import DataTable from "./dataTable";
import API from "../../config/api";
import { DatePicker, Input, Modal, message } from "antd";
import { POST } from "../../utils/apiCalls";
import UserDetailsTable from "./components/userDetailsTable";

const Logs = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>({ pageSize: 10 });
  const [date, setDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [usersModal, setUsersModal] = useState(false);
  const [listedUsers, setListedUsers] = useState([]);

  // Modal states
  const [modalLoading, setModalLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalPage, setModalPage] = useState<number>(1);
  const [modalMeta, setModalMeta] = useState<any>();
  const modalPageSize = 10;

  // Search state for modal users
  const [userSearchText, setUserSearchText] = useState(""); // Search text for modal

  useEffect(() => {
    getData(searchText, date, page, meta.pageSize);
  }, [page, date, searchText]);

  useEffect(() => {
    if (usersModal) getModalData(userSearchText);
  }, [usersModal, modalPage, userSearchText]);

  const getData = async (
    qry: string,
    dates: string,
    currentPage: number,
    currentPageSize: number
  ) => {
    try {
      setLoading(true);
      const api = `${API.LOGGED_USERS}?order=DESC&page=${currentPage}&take=${currentPageSize}`;
      const obj = { date: dates, query: qry.trim() };
      const response: any = await POST(api, obj);

      if (response?.status) {
        setData(response?.data);
        setMeta({
          ...response?.meta,
          pageSize: currentPageSize, // Ensure pageSize is preserved in meta
        });
      } else {
        message.error("Oops! Something went wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  };

  const getModalData = async (query: any) => {
    try {
      setModalLoading(true);
      let api =
        API.LOGGED_USER_DETAILS + `?order=DESC&page=${modalPage}&take=${10}`;
      let obj = {
        query: query.trim(),
        users: listedUsers,
      };
      const response: any = await POST(api, obj);
      if (response?.status) {
        setModalData(response?.data);
        setModalMeta(response?.meta);
      } else {
        message.error("Oops! Something went wrong.");
      }
      setModalLoading(false);
    } catch (err) {
      setModalLoading(false);
    }
  };

  const handlePageChange = (newPage: number, newPageSize?: number) => {
    const updatedPageSize = newPageSize || meta.pageSize;
    setPage(newPage);
    setMeta({ ...meta, pageSize: updatedPageSize });
    getData(searchText, date, newPage, updatedPageSize);
  };

  const handleModalPageChange = (modalPage: number) => {
    setModalPage(modalPage);
  };

  return (
    <div className="Offices">
      <PageHeader title={"Logs"} backButton={true}>
        <div style={{ display: "flex" }}>
          <DatePicker
            style={{ width: "50%" }}
            onChange={(val: any, date: any) => setDate(date)}
            disabledDate={(current) => current && current.isAfter(new Date())}
          />
          &nbsp;&nbsp;
          <Input.Search
            placeholder="Search name, date, or action..."
            allowClear
            enterButton="Search"
            onSearch={(val: any) => setSearchText(val ?? "")}
          />
        </div>
        <Modal
          title={"Blocked Users"}
          width={1000}
          centered
          open={usersModal}
          onCancel={() => {
            setListedUsers([]);
            setUsersModal(false);
            setUserSearchText("");
            setModalPage(1);
          }}
          footer={false}
        >
          <br />
          <Input.Search
            placeholder="Search users"
            allowClear
            enterButton="Search"
            value={userSearchText}
            onTouchCancel={() => {
              setModalPage(1);
              setUserSearchText("");
            }}
            onSearch={(val) => {
              setUserSearchText(val);
              setModalPage(1);
            }}
            onChange={(e) => setUserSearchText(e.target.value)}
            style={{ marginBottom: 16, width: 300 }}
          />
          {modalLoading ? (
            <LoadingBox />
          ) : (
            <UserDetailsTable
              data={modalData}
              handleModalPageChange={handleModalPageChange}
              meta={modalMeta}
              page={modalPage}
              pageSize={modalPageSize}
            />
          )}
        </Modal>
      </PageHeader>
      {loading ? (
        <LoadingBox />
      ) : (
        <DataTable
          data={data}
          handlePageChange={handlePageChange}
          meta={meta}
          page={page}
          setModal={(toggle: any) => setUsersModal(toggle)}
          listedUsers={(users: any) => setListedUsers(users)}
        />
      )}
    </div>
  );
};

export default Logs;
