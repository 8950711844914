import "../styles.scss";
import moment from "moment";
import { Card, message } from "antd";
import API from "../../../config/api";
import Cards from "../components/Cards";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET } from "../../../utils/apiCalls";
import { GiMoneyStack } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import { Col, Row, Table } from "react-bootstrap";
import { FaCircle, FaTags } from "react-icons/fa";
import LoadingBox from "../../../components/loading";
import { PiUsersThreeDuotone } from "react-icons/pi";

function Info(props: any) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [office, setOffice] = useState<any>({});
  const [subCount, setSubCount] = useState<any>();
  const [usersCount, setUsersCount] = useState<any>();
  const [transaction, setTransaction] = useState<any>();

  useEffect(() => {
    getOfficeInfo();
    return props?.gymStatus("");
  }, []);

  const getOfficeInfo = async () => {
    try {
      let url = `${API.GET_OFFICE_INFO + id}`;
      let response: any = await GET(url, null);
      if (response?.status) {
        setOffice(response?.data?.office);
        setUsersCount(response?.data?.users);
        setSubCount(response?.data?.subscription);
        setTransaction(response?.data?.transaction);
        if (response?.data?.office?.status) {
          props?.gymStatus("active");
        } else {
          props?.gymStatus("inactive");
        }
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const cardItems = [
    {
      icon: <PiUsersThreeDuotone />,
      label: "Total Users",
      value: usersCount || 0,
    },
    {
      icon: <FaTags />,
      label: "Total Subscription",
      value: subCount || 0,
    },
    {
      icon: <GrTransaction />,
      label: "Total Transaction",
      value: transaction?.count || 0,
    },
    {
      icon: <GiMoneyStack />,
      label: "Total Transaction Amount",
      value: transaction?.rows[0]?.paid || 0,
    },
  ];
  const renderDetails = (label: any, value: any, limitArea: string = "") => (
    <div className="Info-details">
      <div className={`Info-details-txt1 ${limitArea ? "Info-width" : ""}`}>
        {label}&nbsp;<span>:</span>
      </div>
      <div
        className={`Info-details-txt2 ${
          limitArea === "red"
            ? "Info-limit-count bgc-red"
            : limitArea === "green"
            ? "Info-limit-count bgc-green"
            : limitArea === "blue"
            ? "Info-limit-count bgc-blue"
            : ""
        }`}
      >
        {value}
      </div>
    </div>
  );
  return (
    <div>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <Row>
            {cardItems?.map((card, idx) => (
              <Col md={3} key={idx}>
                <Cards
                  icon={card?.icon}
                  label={card?.label}
                  value={card?.value}
                />
              </Col>
            ))}
          </Row>
          <br />
          <Row>
            <Col md={8} sm={12}>
              <Card title={"Other Details"}>
                <Row>
                  <Col md={6}>
                    {renderDetails("Office Name", office?.name)}
                    {renderDetails("Address", office?.address)}
                    {renderDetails("Email", office?.email)}
                    {renderDetails("Phone", office?.phone)}
                  </Col>
                  <Col md={6}>
                    {renderDetails(
                      "Time",
                      `${moment(office?.opening_time).format(
                        "HH:mm a"
                      )} to ${moment(office?.closing_time).format("HH:mm a")}`
                    )}
                    {renderDetails(
                      "Office Start Date",
                      moment(office?.start_date).format("DD-MM-YYYY")
                    )}
                    {renderDetails(
                      "Office End Date",
                      moment(office?.end_date).format("DD-MM-YYYY")
                    )}
                    {renderDetails("Prefix", office?.prefix)}
                    {renderDetails("Suffix", office?.sufix)}
                  </Col>
                </Row>
                <br />
                <br />
              </Card>
              <br />
              <Card title={`Devices (${office?.devices?.length})`}>
                <Table bordered>
                  <tr>
                    <th>Device Name</th>
                    <th>Device ID</th>
                  </tr>
                  {office?.devices?.map((device: any, idx: number) => (
                    <tr>
                      <td> {device?.device_name}</td>
                      <td>{device?.device_id}</td>
                    </tr>
                  ))}
                </Table>
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card title={"Gym Fee and others"}>
                {renderDetails("Total Fee Paid", office?.paid_amount)}
                {renderDetails(
                  "Start Date",
                  moment(office?.createdAt).format("DD-MM-YYYY")
                )}
              </Card>
              <br />
              <Card title={"User Limit"}>
                {renderDetails(
                  <>
                    <FaCircle color="red" />
                    &nbsp;Limit
                  </>,
                  office?.user_limit || 0,
                  "red"
                )}
                {renderDetails(
                  <>
                    <FaCircle color="#4285f4" />
                    &nbsp;Currrent Users
                  </>,
                  usersCount || 0,
                  "blue"
                )}
                {renderDetails(
                  <>
                    <FaCircle color="#57d449" />
                    &nbsp;Available
                  </>,
                  Number(office?.user_limit) - Number(usersCount) || 0,
                  "green"
                )}
              </Card>
            </Col>
          </Row>
          <br />
        </>
      )}
    </div>
  );
}

export default Info;