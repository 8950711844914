import { useState } from "react";
import PageHeader from "../../components/pageHeader";
import { Button, Tag, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import LoadingBox from "../../components/loading";
import { Tabs } from "antd";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";
import "./styles.scss";

import Info from "./InfoTab";
import Users from "./UsersTab";
import Trainers from "./TrainersTab";
import Transaction from "./TransactionTab";
import Subscription from "./SubscriptionTab";
import {
  ExportTransactions,
  ExportUsers,
} from "./TransactionTab/components/exports";

function Details() {
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const [gymStatus, setgymStatus] = useState("");
  const [activeTab, setActiveTab] = useState(tab);

  const ExportToExcel = async () => {
    try {
      let url = API.ALL_USERS;
      const response: any = await GET(url, null);
      if (response?.status) {
        await ExportUsers(response?.data, "Users");
      } else {
        message.error("Something went wrong. Couldn't Export.");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong. Couldn't Export.");
    }
  };

  const TransactionsExportToExcel = async () => {
    try {
      let url = `${API.ALL_TRANS_BY_OFFICE + id}`;
      const response: any = await GET(url, null);
      if (response?.status) {
        await ExportTransactions(response?.data?.rows, "Transactions");
      } else {
        message.error("Something went wrong. Couldn't Export.");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong. Couldn't Export.");
    }
  };

  const onChange = (tab: any) => {
    navigate(`/admin/details/${tab}/${id}`);
    setActiveTab(tab);
  };

  const items = [
    {
      key: "info",
      label: "Info",
      tab: "info",
      children: <Info gymStatus={(val: string) => setgymStatus(val)} />,
    },
    {
      key: "users",
      label: "Users",
      tab: "users",
      children: <Users />,
    },
    {
      key: "subscription",
      label: "Subscription",
      tab: "subscription",
      children: <Subscription />,
    },
    {
      key: "transaction",
      label: "Transaction",
      tab: "transaction",
      children: <Transaction />,
    },
    {
      key: "trainers",
      label: "Trainers",
      tab: "trainers",
      children: <Trainers />,
    },
  ];

  return (
    <div className="Details">
      <PageHeader
        goBack={"/admin/home"}
        backButton
        title={`Details`}
        children={
          <div className="PageHeader-children">
            {tab === "info" && (
              <div className="PageHeader-gym-name">
                {gymStatus === "active" ? (
                  <Tag color="green">Active</Tag>
                ) : gymStatus === "inactive" ? (
                  <Tag color="red">Blocked</Tag>
                ) : (
                  ""
                )}
              </div>
            )}
            {tab === "users" && (
              <>
                <div style={{ width: 20 }} />
                <Button onClick={() => ExportToExcel()} type="primary">
                  Exoprt Excel
                </Button>
              </>
            )}
            {tab === "transaction" && (
              <>
                <div style={{ width: 20 }} />
                <Button
                  onClick={() => TransactionsExportToExcel()}
                  type="primary"
                >
                  Exoprt Excel
                </Button>
              </>
            )}
          </div>
        }
      />
      <Container>
        <Tabs defaultActiveKey={activeTab} onChange={onChange}>
          {items?.map((item: any) => (
            <Tabs.TabPane key={item?.key} tab={item?.label}>
              {item?.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Container>
    </div>
  );
}

export default Details;
