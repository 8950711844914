export const userColumns = [
  {
    header: "Name",
    key: "name",
  },
  {
    header: "Admission ID",
    key: "admisson_id",
  },
  {
    header: "Status",
    key: "status",
  },
  {
    header: "End Date",
    key: "end_date",
  },
];