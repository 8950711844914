import { Pagination, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { MdBlock } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";
const DataTable = (props: any) => {
  const columns = [
    {
      title: "Office Name",
      dataIndex: "Office.name",
      key: "Office.name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (item: any) => (
        <div>
          {item} &nbsp;
          {item === "unblock" ? (
            <CgUnblock size={20} color="green" />
          ) : (
            <MdBlock size={20} color="red" />
          )}
        </div>
      ),
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: (item: any) => (
        <Tooltip title={item?.join(", ")}>
          <Tag
            onClick={() => {
              props?.setModal(true);
              props?.listedUsers(item);
            }}
          >
            {item?.length} Users
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: "Logged on",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item: any) => (
        <div style={{ textAlign: "right", width: "100%" }}>
          {moment(item).format("DD-MM-YYYY [at] hh:mm A")}
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
      <Pagination
        current={props?.page || 1}
        pageSize={props?.meta?.pageSize || 10}
        total={props?.meta?.itemCount || 0}
        showSizeChanger={true}
        pageSizeOptions={["10", "20", "50", "100"]}
        onChange={(page, pageSize) => {
          props?.handlePageChange(page, pageSize);
        }}
        onShowSizeChange={(current, size) => {
          props?.handlePageChange(1, size);
        }}
        style={{ marginTop: "20px", textAlign: "center" }}
      />
      <br />
    </>
  );
};

export default DataTable;
