export const TransColumns = [
  {
    header: "Name",
    key: "user_name",
  },
  {
    header: "User ID",
    key: "user_id",
  },
  {
    header: "Amount",
    key: "amount",
  },
  {
    header: "Paid",
    key: "paid_mount",
  },
  {
    header: "Method",
    key: "payment_method",
  },
  {
    header: "Transaction ID",
    key: "transaction_id",
  },
  {
    header: "Created On",
    key: "createdAt",
  },
];